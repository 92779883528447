/**
 * External dependencies
 */
import { combineReducers } from '@wordpress/data';
import {
	castArray,
	reject,
	omit,
	keyBy,
	map,
	find,
} from 'lodash';

/**
 * Internal dependencies
 */
import getQueryParts from './utils/get-query-parts';
import getMergedItemIds from './utils/get-merged-item-ids';
import {
	AppStateTeachers,
	AppStateUserRememberedItems,
	RememberedItem,
	AppStateAuthentication,
	AppStateApprenticeshipSlot,
	AppStateTrialApprenticeship,
	AppStateEventItem,
} from './types';

interface BaseAction {
	type: string;
}

interface AuthenticationAction extends BaseAction {
	error: any;
}

/**
 * Reducer returning the next authentication state.
 */
export function authentication( state: AppStateAuthentication = {
	loggingIn: false,
	registering: false,
	error: null,
}, action: AuthenticationAction ) {
	switch ( action.type ) {
		case 'LOGIN_REQUEST':
			return {
				...state,
				loggingIn: true,
				error: null,
			};

		case 'LOGIN_SUCCESS':
			return {
				...state,
				loggingIn: false,
				error: null,
			};

		case 'LOGIN_FAILURE':
			return {
				...state,
				loggingIn: false,
				error: action.error,
			};

		case 'REGISTRATION_REQUEST':
			return {
				...state,
				registering: true,
				error: null,
			};

		case 'REGISTRATION_SUCCESS':
			return {
				...state,
				registering: false,
				error: null,
			};

		case 'REGISTRATION_FAILURE':
			return {
				...state,
				registering: false,
				error: action.error,
			};

		default:
			return state;
	}
}

/**
 * Reducer returning the profile.
 */
export function profile( state = {
	isUpdating: false,
	isFetching: false,
	data: {},
}, action ) {
	switch ( action.type ) {
		case 'FETCH_PROFILE':
			return {
				...state,
				isFetching: true,
			};

		case 'UPDATE_PROFILE_REQUEST':
			return {
				...state,
				isUpdating: true,
			};

		case 'FETCH_PROFILE_SUCCESS':
		case 'FETCH_PROFILE_FAILURE':
			return {
				...state,
				isFetching: false,
			};

		case 'UPDATE_PROFILE_SUCCESS':
		case 'UPDATE_PROFILE_FAILURE':
			return {
				...state,
				isUpdating: false,
			};

		case 'RECEIVE_PROFILE':
			return {
				...state,
				data: action.profile,
			};

		case 'REMOVE_PROFILE':
			return {
				isFetching: false,
				data: {},
			};

		default:
			return state;
	}
}

/**
 * Reducer returning the persistent auth token.
 */
export function token( state = '', action ) {
	switch ( action.type ) {
		case 'SET_AUTH_TOKEN':
			return action.token;

		case 'REMOVE_AUTH_TOKEN':
			return '';

		default:
			return state;
	}
}

/**
 * Reducer returning the open state of the modal window.
 */
export function loginModal( state = false, action ) {
	switch ( action.type ) {
		case 'OPEN_LOGIN_MODAL':
			return true;

		case 'CLOSE_LOGIN_MODAL':
			return false;

		default:
			return state;
	}
}

/**
 * Reducer returning the next notices state.
 */
export function notices( state = [], action ) {
	switch ( action.type ) {
		case 'CREATE_NOTICE':
			// Avoid duplicates on ID.
			return [
				...reject( state, { id: action.notice.id } ),
				action.notice,
			];

		case 'REMOVE_NOTICE':
			return reject( state, { id: action.id } );

		case 'REMOVE_ALL_NOTICES':
			return [];

		default:
			return state;
	}
}

/**
 * Reducer returning the next pages state.
 */
export function pages( state = {
	isFetching: {},
	results: {},
	errors: {},
}, action ) {
	const pageKey = `${ action.pagePath }${ action.pagePassword }`;

	switch ( action.type ) {
		case 'RECEIVE_PAGE':
			return {
				...state,
				results: {
					...state.results,
					[ pageKey ]: action.page,
				},
			};

		case 'FETCH_PAGE':
			return {
				...state,
				isFetching: {
					...state.isFetching,
					[ pageKey ]: true,
				},
			};

		case 'FETCH_PAGE_SUCCESS':
			return {
				...state,
				isFetching: omit( state.isFetching, pageKey ),
				errors: omit( state.errors, pageKey ),
			};

		case 'FETCH_PAGE_FAILURE':
			return {
				...state,
				isFetching: omit( state.isFetching, pageKey ),
				errors: {
					...state.errors,
					[ pageKey ]: action.error,
				},
			};

		default:
			return state;
	}
}

/**
 * Reducer returning the next professions state.
 */
export function posts( state = {
	isFetching: {},
	items: {},
	queries: {},
	pagination: {},
}, action ) {
	if ( ! ( 'query' in action ) ) {
		return state;
	}

	const {
		stableKey,
		page,
		perPage,
	} = getQueryParts( action.query );

	switch ( action.type ) {
		case 'RECEIVE_POSTS':
			return {
				...state,
				queries: {
					...state.queries,
					[ stableKey ]: getMergedItemIds(
						state.queries[ stableKey ] || [],
						map( action.items, 'slug' ),
						page,
						perPage,
					),
				},
				items: {
					...state.items,
					...keyBy( action.items, 'slug' ),
				},
				pagination: {
					...state.pagination,
					[ stableKey ]: {
						total: action.total,
						totalPages: action.totalPages,
					},
				},
			};

		case 'FETCH_POSTS':
			return {
				...state,
				isFetching: {
					...state.isFetching,
					[ stableKey ]: true,
				},
			};

		case 'FETCH_POSTS_SUCCESS':
		case 'FETCH_POSTS_FAILURE':
			return {
				...state,
				isFetching: omit( state.isFetching, stableKey ),
			};

		default:
			return state;
	}
}

/**
 * Reducer returning the next schools state.
 */
export function schools( state = {
	isFetching: false,
	results: [],
}, action ) {
	switch ( action.type ) {
		case 'RECEIVE_SCHOOLS':
			return {
				...state,
				results: castArray( action.schools ),
			};

		case 'FETCH_SCHOOLS':
			return {
				...state,
				isFetching: true,
			};

		case 'FETCH_SCHOOLS_SUCCESS':
		case 'FETCH_SCHOOLS_FAILURE':
			return {
				...state,
				isFetching: false,
			};

		default:
			return state;
	}
}

/**
 * Reducer returning the next teachers state.
 */
export function teachers( state: AppStateTeachers = {
	isFetching: {},
	results: {},
}, action ) {
	switch ( action.type ) {
		case 'RECEIVE_TEACHERS':
			return {
				...state,
				results: {
					...state.results,
					[ action.schoolID ]: action.teachers,
				},
			};

		case 'FETCH_TEACHERS':
			return {
				...state,
				isFetching: {
					...state.isFetching,
					[ action.schoolID ]: true,
				},
			};

		case 'FETCH_TEACHERS_SUCCESS':
		case 'FETCH_TEACHERS_FAILURE':
			return {
				...state,
				isFetching: omit( state.isFetching, action.schoolID ),
			};

		default:
			return state;
	}
}

/**
 * Reducer returning the next districts state.
 */
export function districts( state = {
	isFetching: {},
	items: {},
	queries: {},
}, action ) {
	if ( ! ( 'query' in action ) ) {
		return state;
	}

	const {
		stableKey,
		page,
		perPage,
	} = getQueryParts( action.query );

	switch ( action.type ) {
		case 'RECEIVE_DISTRICTS':
			return {
				...state,
				queries: {
					...state.queries,
					[ stableKey ]: getMergedItemIds(
						state.queries[ stableKey ] || [],
						map( action.items, 'id' ),
						page,
						perPage,
					),
				},
				items: {
					...state.items,
					...keyBy( action.items, 'id' ),
				},
			};

		case 'FETCH_DISTRICTS':
			return {
				...state,
				isFetching: {
					...state.isFetching,
					[ stableKey ]: true,
				},
			};

		case 'FETCH_DISTRICTS_SUCCESS':
		case 'FETCH_DISTRICTS_FAILURE':
			return {
				...state,
				isFetching: omit( state.isFetching, stableKey ),
			};

		default:
			return state;
	}
}

/**
 * Reducer returning the next cities state.
 */
export function cities( state = {
	isFetching: false,
	results: [],
}, action ) {
	switch ( action.type ) {
		case 'RECEIVE_CITIES':
			return {
				...state,
				results: castArray( action.cities ),
			};

		case 'FETCH_CITIES':
			return {
				...state,
				isFetching: true,
			};

		case 'FETCH_CITIES_SUCCESS':
		case 'FETCH_CITIES_FAILURE':
			return {
				...state,
				isFetching: false,
			};

		default:
			return state;
	}
}

/**
 * Reducer returning the next event audience state.
 */
export function eventAudience( state = {
	isFetching: false,
	results: [],
}, action ) {
	switch ( action.type ) {
		case 'RECEIVE_EVENT_AUDIENCE':
			return {
				...state,
				results: castArray( action.audience ),
			};

		case 'FETCH_EVENT_AUDIENCE':
			return {
				...state,
				isFetching: true,
			};

		case 'FETCH_EVENT_AUDIENCE_SUCCESS':
		case 'FETCH_EVENT_AUDIENCE_FAILURE':
			return {
				...state,
				isFetching: false,
			};

		default:
			return state;
	}
}

/**
 * Reducer returning the next event topics state.
 */
export function eventTopics( state = {
	isFetching: false,
	results: [],
}, action ) {
	switch ( action.type ) {
		case 'RECEIVE_EVENT_TOPICS':
			return {
				...state,
				results: castArray( action.topics ),
			};

		case 'FETCH_EVENT_TOPICS':
			return {
				...state,
				isFetching: true,
			};

		case 'FETCH_EVENT_TOPICS_SUCCESS':
		case 'FETCH_EVENT_TOPICS_FAILURE':
			return {
				...state,
				isFetching: false,
			};

		default:
			return state;
	}
}

/**
 * Reducer returning the next offices state.
 */
export function offices( state = {
	isFetching: {},
	items: {},
	queries: {},
	pagination: {},
}, action ) {
	if ( ! ( 'query' in action ) ) {
		return state;
	}

	const {
		stableKey,
		page,
		perPage,
	} = getQueryParts( action.query );

	switch ( action.type ) {
		case 'RECEIVE_OFFICES':
			return {
				...state,
				queries: {
					...state.queries,
					[ stableKey ]: getMergedItemIds(
						state.queries[ stableKey ] || [],
						map( action.items, 'slug' ),
						page,
						perPage,
					),
				},
				items: {
					...state.items,
					...keyBy( action.items, 'slug' ),
				},
				pagination: {
					...state.pagination,
					[ stableKey ]: {
						total: action.total,
						totalPages: action.totalPages,
					},
				},
			};

		case 'FETCH_OFFICES':
			return {
				...state,
				isFetching: {
					...state.isFetching,
					[ stableKey ]: true,
				},
			};

		case 'FETCH_OFFICES_SUCCESS':
		case 'FETCH_OFFICES_FAILURE':
			return {
				...state,
				isFetching: omit( state.isFetching, stableKey ),
			};

		default:
			return state;
	}
}

/**
 * Reducer returning the next profession fields state.
 */
export function professionFields( state = {
	isFetching: false,
	results: [],
}, action ) {
	switch ( action.type ) {
		case 'RECEIVE_PROFESSION_FIELDS':
			return {
				...state,
				results: castArray( action.fields ),
			};

		case 'FETCH_PROFESSION_FIELDS':
			return {
				...state,
				isFetching: true,
			};

		case 'FETCH_PROFESSION_FIELDS_SUCCESS':
		case 'FETCH_PROFESSION_FIELDS_FAILURE':
			return {
				...state,
				isFetching: false,
			};

		default:
			return state;
	}
}

/**
 * Reducer returning the next profession interests state.
 */
export function professionInterests( state = {
	isFetching: false,
	results: [],
}, action ) {
	switch ( action.type ) {
		case 'RECEIVE_PROFESSION_INTERESTS':
			return {
				...state,
				results: castArray( action.interests ),
			};

		case 'FETCH_PROFESSION_INTERESTS':
			return {
				...state,
				isFetching: true,
			};

		case 'FETCH_PROFESSION_INTERESTS_SUCCESS':
		case 'FETCH_PROFESSION_INTERESTS_FAILURE':
			return {
				...state,
				isFetching: false,
			};

		default:
			return state;
	}
}

/**
 * Reducer returning the next profession sectors state.
 */
export function professionSectors( state = {
	isFetching: false,
	results: [],
}, action ) {
	switch ( action.type ) {
		case 'RECEIVE_PROFESSION_SECTORS':
			return {
				...state,
				results: castArray( action.sectors ),
			};

		case 'FETCH_PROFESSION_SECTORS':
			return {
				...state,
				isFetching: true,
			};

		case 'FETCH_PROFESSION_SECTORS_SUCCESS':
		case 'FETCH_PROFESSION_SECTORS_FAILURE':
			return {
				...state,
				isFetching: false,
			};

		default:
			return state;
	}
}

/**
 * Reducer returning the next profession durations state.
 */
export function professionDurations( state = {
	isFetching: false,
	results: [],
}, action ) {
	switch ( action.type ) {
		case 'RECEIVE_PROFESSION_DURATIONS':
			return {
				...state,
				results: castArray( action.durations ),
			};

		case 'FETCH_PROFESSION_DURATIONS':
			return {
				...state,
				isFetching: true,
			};

		case 'FETCH_PROFESSION_DURATIONS_SUCCESS':
		case 'FETCH_PROFESSION_DURATIONS_FAILURE':
			return {
				...state,
				isFetching: false,
			};

		default:
			return state;
	}
}

/**
 * Reducer returning the next profession qualifications state.
 */
export function professionQualifications( state = {
	isFetching: false,
	results: [],
}, action ) {
	switch ( action.type ) {
		case 'RECEIVE_PROFESSION_QUALIFICATIONS':
			return {
				...state,
				results: castArray( action.qualifications ),
			};

		case 'FETCH_PROFESSION_QUALIFICATIONS':
			return {
				...state,
				isFetching: true,
			};

		case 'FETCH_PROFESSION_QUALIFICATIONS_SUCCESS':
		case 'FETCH_PROFESSION_QUALIFICATIONS_FAILURE':
			return {
				...state,
				isFetching: false,
			};

		default:
			return state;
	}
}

/**
 * Reducer returning the next professions state.
 */
export function professions( state = {
	isFetching: {},
	items: {},
	queries: {},
	pagination: {},
}, action ) {
	if ( ! ( 'query' in action ) ) {
		return state;
	}

	const {
		stableKey,
		page,
		perPage,
	} = getQueryParts( action.query );

	switch ( action.type ) {
		case 'RECEIVE_PROFESSIONS':
			return {
				...state,
				queries: {
					...state.queries,
					[ stableKey ]: getMergedItemIds(
						state.queries[ stableKey ] || [],
						map( action.items, 'slug' ),
						page,
						perPage,
					),
				},
				items: {
					...state.items,
					...keyBy( action.items, 'slug' ),
				},
				pagination: {
					...state.pagination,
					[ stableKey ]: {
						total: action.total,
						totalPages: action.totalPages,
					},
				},
			};

		case 'FETCH_PROFESSIONS':
			return {
				...state,
				isFetching: {
					...state.isFetching,
					[ stableKey ]: true,
				},
			};

		case 'FETCH_PROFESSIONS_SUCCESS':
		case 'FETCH_PROFESSIONS_FAILURE':
			return {
				...state,
				isFetching: omit( state.isFetching, stableKey ),
			};

		default:
			return state;
	}
}

/**
 * Reducer returning the next apprenticeship professions state.
 */
export function apprenticeshipProfessions( state = {
	isFetching: {},
	items: {},
	queries: {},
}, action ) {
	if ( ! ( 'query' in action ) ) {
		return state;
	}

	const {
		stableKey,
		page,
		perPage,
	} = getQueryParts( action.query );

	switch ( action.type ) {
		case 'RECEIVE_APPRENTICESHIP_PROFESSIONS':
			return {
				...state,
				queries: {
					...state.queries,
					[ stableKey ]: getMergedItemIds(
						state.queries[ stableKey ] || [],
						map( action.items, 'slug' ),
						page,
						perPage,
					),
				},
				items: {
					...state.items,
					...keyBy( action.items, 'slug' ),
				},
			};

		case 'FETCH_APPRENTICESHIP_PROFESSIONS':
			return {
				...state,
				isFetching: {
					...state.isFetching,
					[ stableKey ]: true,
				},
			};

		case 'FETCH_APPRENTICESHIP_PROFESSIONS_SUCCESS':
		case 'FETCH_APPRENTICESHIP_PROFESSIONS_FAILURE':
			return {
				...state,
				isFetching: omit( state.isFetching, stableKey ),
			};

		default:
			return state;
	}
}

/**
 * Reducer returning the next apprenticeship locations state.
 */
export function apprenticeshipLocations( state = {
	isFetching: {},
	items: {},
	queries: {},
}, action ) {
	if ( ! ( 'query' in action ) ) {
		return state;
	}

	const {
		stableKey,
		page,
		perPage,
	} = getQueryParts( action.query );

	switch ( action.type ) {
		case 'RECEIVE_APPRENTICESHIP_LOCATIONS':
			return {
				...state,
				queries: {
					...state.queries,
					[ stableKey ]: getMergedItemIds(
						state.queries[ stableKey ] || [],
						map( action.items, 'id' ),
						page,
						perPage,
					),
				},
				items: {
					...state.items,
					...keyBy( action.items, 'id' ),
				},
			};

		case 'FETCH_APPRENTICESHIP_LOCATIONS':
			return {
				...state,
				isFetching: {
					...state.isFetching,
					[ stableKey ]: true,
				},
			};

		case 'FETCH_APPRENTICESHIP_LOCATIONS_SUCCESS':
		case 'FETCH_APPRENTICESHIP_LOCATIONS_FAILURE':
			return {
				...state,
				isFetching: omit( state.isFetching, stableKey ),
			};

		default:
			return state;
	}
}

/**
 * Reducer returning the next apprenticeships state.
 */
export function apprenticeships( state = {
	isFetching: {},
	items: {},
	queries: {},
}, action ) {
	if ( ! ( 'query' in action ) ) {
		return state;
	}

	const {
		stableKey,
		page,
		perPage,
	} = getQueryParts( action.query );

	switch ( action.type ) {
		case 'RECEIVE_APPRENTICESHIPS':
			return {
				...state,
				queries: {
					...state.queries,
					[ stableKey ]: getMergedItemIds(
						state.queries[ stableKey ] || [],
						map( action.items, 'slug' ),
						page,
						perPage,
					),
				},
				items: {
					...state.items,
					...keyBy( action.items, 'slug' ),
				},
			};

		case 'FETCH_APPRENTICESHIPS':
			return {
				...state,
				isFetching: {
					...state.isFetching,
					[ stableKey ]: true,
				},
			};

		case 'FETCH_APPRENTICESHIPS_SUCCESS':
		case 'FETCH_APPRENTICESHIPS_FAILURE':
			return {
				...state,
				isFetching: omit( state.isFetching, stableKey ),
			};

		default:
			return state;
	}
}

/**
 * Reducer returning the next trial apprenticeships state.
 */
export function trialApprenticeships( state = {
	isFetching: {},
	items: {},
	queries: {},
}, action ) {
	if ( ! ( 'query' in action ) ) {
		return state;
	}

	const {
		stableKey,
		page,
		perPage,
	} = getQueryParts( action.query );

	switch ( action.type ) {
		case 'RECEIVE_TRIAL_APPRENTICESHIPS':
			return {
				...state,
				queries: {
					...state.queries,
					[ stableKey ]: getMergedItemIds(
						state.queries[ stableKey ] || [],
						map( action.items, 'slug' ),
						page,
						perPage,
					),
				},
				items: {
					...state.items,
					...keyBy( action.items, 'slug' ),
				},
			};

		case 'FETCH_TRIAL_APPRENTICESHIPS':
			return {
				...state,
				isFetching: {
					...state.isFetching,
					[ stableKey ]: true,
				},
			};

		case 'FETCH_TRIAL_APPRENTICESHIPS_SUCCESS':
		case 'FETCH_TRIAL_APPRENTICESHIPS_FAILURE':
			return {
				...state,
				isFetching: omit( state.isFetching, stableKey ),
			};

		default:
			return state;
	}
}

/**
 * Reducer returning the next events state.
 */
export function events( state = {
	isFetching: {},
	items: {},
	queries: {},
	pagination: {},
}, action ) {
	if ( ! ( 'query' in action ) ) {
		return state;
	}

	const {
		stableKey,
		page,
		perPage,
	} = getQueryParts( action.query );

	switch ( action.type ) {
		case 'RECEIVE_EVENTS':
			return {
				...state,
				queries: {
					...state.queries,
					[ stableKey ]: getMergedItemIds(
						state.queries[ stableKey ] || [],
						map( action.items, 'slug' ),
						page,
						perPage,
					),
				},
				items: {
					...state.items,
					...keyBy( action.items, 'slug' ),
				},
				pagination: {
					...state.pagination,
					[ stableKey ]: {
						total: action.total,
						totalPages: action.totalPages,
					},
				},
			};

		case 'FETCH_EVENTS':
			return {
				...state,
				isFetching: {
					...state.isFetching,
					[ stableKey ]: true,
				},
			};

		case 'FETCH_EVENTS_SUCCESS':
		case 'FETCH_EVENTS_FAILURE':
			return {
				...state,
				isFetching: omit( state.isFetching, stableKey ),
			};

		default:
			return state;
	}
}

/**
 * Reducer returning the next search results state.
 */
export function searchResults( state = {
	isFetching: {},
	items: {},
	queries: {},
	pagination: {},
}, action ) {
	if ( ! ( 'query' in action ) ) {
		return state;
	}

	const {
		stableKey,
		page,
		perPage,
	} = getQueryParts( action.query );

	switch ( action.type ) {
		case 'RECEIVE_SEARCH_RESULTS':
			return {
				...state,
				queries: {
					...state.queries,
					[ stableKey ]: getMergedItemIds(
						state.queries[ stableKey ] || [],
						map( action.items, 'id' ),
						page,
						perPage,
					),
				},
				items: {
					...state.items,
					...keyBy( action.items, 'id' ),
				},
				pagination: {
					...state.pagination,
					[ stableKey ]: {
						total: action.total,
						totalPages: action.totalPages,
					},
				},
			};

		case 'FETCH_SEARCH_RESULTS':
			return {
				...state,
				isFetching: {
					...state.isFetching,
					[ stableKey ]: true,
				},
			};

		case 'FETCH_SEARCH_RESULTS_SUCCESS':
		case 'FETCH_SEARCH_RESULTS_FAILURE':
			return {
				...state,
				isFetching: omit( state.isFetching, stableKey ),
			};

		default:
			return state;
	}
}

/**
 * Reducer returning the form.
 */
export function form( state = {
	isFetching: false,
	data: {},
}, {
	type, data, response, error,
} ) {
	switch ( type ) {
		case 'FETCH_FORM_REQUEST':
			return {
				...state,
				data,
				isFetching: true,
			};

		case 'FETCH_FORM_SUCCESS':
		case 'FETCH_FORM_FAILURE':
			return {
				...state,
				response,
				error,
				isFetching: false,
			};

		case 'RESET_FORM_DATA':
			return {
				isFetching: false,
				data: {},
			};

		default:
			return state;
	}
}

/**
 * Reducer returning the user connection request.
 */
export function userConnectionRequest( state = {
	isFetching: false,
	data: {},
}, {
	type,
	data,
	response,
	error,
} ) {
	switch ( type ) {
		case 'REQUEST_USER_CONNECTION_INIT':
			return {
				...state,
				data,
				isFetching: true,
			};

		case 'REQUEST_USER_CONNECTION_SUCCESS':
		case 'REQUEST_USER_CONNECTION_FAILURE':
			return {
				...state,
				response,
				error,
				isFetching: false,
			};

		default:
			return state;
	}
}

/**
 * Reducer returning the user connection deletion.
 */
export function userConnectionDeletion( state = {
	isFetching: false,
	id: 0,
}, {
	type,
	id,
	response,
	error,
} ) {
	switch ( type ) {
		case 'DELETE_USER_CONNECTION_INIT':
			return {
				...state,
				id,
				isFetching: true,
			};

		case 'DELETE_USER_CONNECTION_SUCCESS':
		case 'DELETE_USER_CONNECTION_FAILURE':
			return {
				...state,
				response,
				error,
				isFetching: false,
			};

		default:
			return state;
	}
}

/**
 * Reducer returning the next user connections state.
 */
export function userConnections( state = {
	isFetching: false,
	results: {},
}, action ) {
	switch ( action.type ) {
		case 'RECEIVE_USER_CONNECTIONS':
			return {
				...state,
				results: { ...keyBy( action.connections, 'id' ) },
			};

		case 'FETCH_USER_CONNECTIONS':
			return {
				...state,
				isFetching: true,
			};

		case 'FETCH_USER_CONNECTIONS_SUCCESS':
		case 'FETCH_USER_CONNECTIONS_FAILURE':
			return {
				...state,
				isFetching: false,
			};

		case 'DELETE_USER_CONNECTION_SUCCESS':
			return {
				...state,
				results: omit( state.results, action.id ),
			};

		case 'UPDATE_USER_CONNECTION_SUCCESS':
			const {
				id,
				changes,
			} = action;

			return {
				...state,
				results: {
					...state.results,
					[ id ]: {
						...state.results[ id ],
						...changes,
					},
				},
			};

		case 'REQUEST_USER_CONNECTION_SUCCESS':
			return {
				...state,
				results: {
					...state.results,
					[ action.connection.id ]: action.connection,
				},
			};

		default:
			return state;
	}
}

/**
 * Reducer returning the next current user remembered items state.
 */
export function currentUserRememberedItems( state = {
	isFetching: false,
	results: null,
}, action ) {
	switch ( action.type ) {
		case 'RECEIVE_CURRENT_USER_REMEMBERED_ITEMS':
			return {
				...state,
				results: { ...keyBy( action.items, 'id' ) },
			};

		case 'FETCH_CURRENT_USER_REMEMBERED_ITEMS':
			return {
				...state,
				isFetching: true,
			};

		case 'FETCH_CURRENT_USER_REMEMBERED_ITEMS_SUCCESS':
		case 'FETCH_CURRENT_USER_REMEMBERED_ITEMS_FAILURE':
			return {
				...state,
				isFetching: false,
			};

		case 'DELETE_CURRENT_USER_REMEMBERED_ITEM_SUCCESS':
			return {
				...state,
				results: omit( state.results, action.id ),
			};

		case 'ADD_CURRENT_USER_REMEMBERED_ITEM_SUCCESS':
			return {
				...state,
				results: {
					...state.results,
					[ action.item.id ]: action.item,
				},
			};

		case 'INVALIDATE_CURRENT_USER_REMEMBERED_ITEMS':
			return {
				...state,
				results: null,
			};

		default:
			return state;
	}
}

interface UserRememberedItemsAction extends BaseAction {
	id: number;
	items: RememberedItem[];
}

/**
 * Reducer returning the next user remembered items state.
 */
export function userRememberedItems( state: AppStateUserRememberedItems = {
	isFetching: {},
	results: {},
}, action: UserRememberedItemsAction ) {
	switch ( action.type ) {
		case 'RECEIVE_USER_REMEMBERED_ITEMS':
			return {
				...state,
				results: {
					...state.results,
					[ action.id ]: { ...keyBy( action.items, 'id' ) },
				},
			};

		case 'FETCH_USER_REMEMBERED_ITEMS':
			return {
				...state,
				isFetching: {
					...state.isFetching,
					[ action.id ]: true,
				},
			};

		case 'FETCH_USER_REMEMBERED_ITEMS_SUCCESS':
		case 'FETCH_USER_REMEMBERED_ITEMS_FAILURE':
			return {
				...state,
				isFetching: omit( state.isFetching, action.id ),
			};

		case 'INVALIDATE_USER_REMEMBERED_ITEMS':
			return {
				...state,
				results: {},
			};

		default:
			return state;
	}
}

/**
 * Reducer returning the next users state.
 */
export function users( state = {
	isFetching: {},
	items: {},
}, action ) {
	switch ( action.type ) {
		case 'RECEIVE_USER':
			return {
				...state,
				items: {
					...state.items,
					[ action.user.id ]: action.user,
				},
			};

		case 'FETCH_USER':
			return {
				...state,
				isFetching: {
					...state.isFetching,
					[ action.id ]: true,
				},
			};

		case 'FETCH_USER_SUCCESS':
		case 'FETCH_USER_FAILURE':
			return {
				...state,
				isFetching: omit( state.isFetching, action.id ),
			};

		case 'INVALIDATE_USERS':
			return {
				...state,
				items: {},
			};

		default:
			return state;
	}
}

/**
 * Reducer returning the next languages state.
 */
export function languages( state = {
	isFetching: false,
	results: [],
}, action ) {
	switch ( action.type ) {
		case 'RECEIVE_LANGUAGES':
			return {
				...state,
				results: castArray( action.languages ),
			};

		case 'FETCH_LANGUAGES':
			return {
				...state,
				isFetching: true,
			};

		case 'FETCH_LANGUAGES_SUCCESS':
		case 'FETCH_LANGUAGES_FAILURE':
			return {
				...state,
				isFetching: false,
			};

		default:
			return state;
	}
}

/**
 * Reducer returning the next nationalities state.
 */
export function nationalities( state = {
	isFetching: false,
	results: [],
}, action ) {
	switch ( action.type ) {
		case 'RECEIVE_NATIONALITIES':
			return {
				...state,
				results: castArray( action.nationalities ),
			};

		case 'FETCH_NATIONALITIES':
			return {
				...state,
				isFetching: true,
			};

		case 'FETCH_NATIONALITIES_SUCCESS':
		case 'FETCH_NATIONALITIES_FAILURE':
			return {
				...state,
				isFetching: false,
			};

		default:
			return state;
	}
}

/**
 * Reducer returning the next user activities state.
 */
export function currentUserActivities( state = {
	isFetching: false,
	results: [],
}, action ) {
	switch ( action.type ) {
		case 'RECEIVE_CURRENT_USER_ACTIVITIES':
			return {
				...state,
				results: castArray( action.activities ),
			};

		case 'FETCH_CURRENT_USER_ACTIVITIES':
			return {
				...state,
				isFetching: true,
			};

		case 'FETCH_CURRENT_USER_ACTIVITIES_SUCCESS':
		case 'FETCH_CURRENT_USER_ACTIVITIES_FAILURE':
			return {
				...state,
				isFetching: false,
			};

		case 'INVALIDATE_CURRENT_USER_ACTIVITIES':
			return {
				...state,
				results: [],
			};

		default:
			return state;
	}
}

/**
 * Reducer returning the next user activities state.
 */
export function userActivities( state = {
	isFetching: {},
	items: {},
}, action ) {
	switch ( action.type ) {
		case 'RECEIVE_USER_ACTIVITIES':
			return {
				...state,
				items: {
					...state.items,
					[ action.id ]: castArray( action.activities ),
				},
			};

		case 'FETCH_USER_ACTIVITIES':
			return {
				...state,
				isFetching: {
					...state.isFetching,
					[ action.id ]: true,
				},
			};

		case 'FETCH_USER_ACTIVITIES_SUCCESS':
		case 'FETCH_USER_ACTIVITIES_FAILURE':
			return {
				...state,
				isFetching: omit( state.isFetching, action.id ),
			};

		case 'INVALIDATE_USER_ACTIVITIES':
			return {
				...state,
				items: {},
			};

		default:
			return state;
	}
}

/**
 * Reducer returning the next news state.
 */
export function news( state = {
	isFetching: false,
	results: [],
}, action ) {
	switch ( action.type ) {
		case 'RECEIVE_NEWS':
			return {
				...state,
				results: castArray( action.items ),
			};

		case 'FETCH_NEWS':
			return {
				...state,
				isFetching: true,
			};

		case 'FETCH_NEWS_SUCCESS':
		case 'FETCH_NEWS_FAILURE':
			return {
				...state,
				isFetching: false,
			};

		default:
			return state;
	}
}

/**
 * Reducer returning the next organizations state.
 */
export function organizations( state = {
	isFetching: {},
	isUpdating: {},
	items: {},
	queries: {},
	pagination: {},
}, action ) {
	// Non-collection-based actions

	let organizationId;
	let eventToChange;
	let organizationToChange;
	let apprenticeshipToChange;
	let slotToChange;
	switch ( action.type ) {
		case 'RECEIVE_ORGANIZATION':
			return {
				...state,
				items: {
					...state.items,
					[ action.organization.slug ]: action.organization,
				},
			};

		case 'CREATE_EVENT_SUCCESS':
			const addedEvent: AppStateEventItem = action.event;
			organizationId = addedEvent.organization?.id;
			if ( ! organizationId ) {
				return state;
			}

			organizationToChange = find( state.items, [ 'id', organizationId ] );
			if ( ! organizationToChange ) {
				return state;
			}

			return {
				...state,
				items: {
					...state.items,
					[ organizationToChange.slug ]: {
						...organizationToChange,
						events: [ ...organizationToChange.events, addedEvent ],
					},
				},
			};

		case 'UPDATE_EVENT_SUCCESS':
			const changedEvent: AppStateEventItem = action.event;
			organizationId = changedEvent.organization?.id;
			if ( ! organizationId ) {
				return state;
			}

			organizationToChange = find( state.items, [ 'id', organizationId ] );
			if ( ! organizationToChange ) {
				return state;
			}

			eventToChange = find( organizationToChange.events, [ 'id', changedEvent.id ] );
			if ( ! eventToChange ) {
				return state;
			}

			return {
				...state,
				items: {
					...state.items,
					[ organizationToChange.slug ]: {
						...organizationToChange,
						events: map( organizationToChange.events, ( eventItem: AppStateEventItem ) => {
							if ( eventItem.id === changedEvent.id ) {
								return {
									...eventItem,
									...changedEvent,
								};
							}
							return eventItem;
						} ),
					},
				},
			};

		case 'DELETE_EVENT_SUCCESS':
			const deletedEvent: AppStateEventItem = action.response;
			organizationId = deletedEvent.organization?.id;
			if ( ! organizationId ) {
				return state;
			}

			organizationToChange = find( state.items, [ 'id', organizationId ] );
			if ( ! organizationToChange ) {
				return state;
			}

			eventToChange = find( organizationToChange.events, [ 'id', deletedEvent.id ] );
			if ( ! eventToChange ) {
				return state;
			}

			return {
				...state,
				items: {
					...state.items,
					[ organizationToChange.slug ]: {
						...organizationToChange,
						events: organizationToChange.events.filter( ( eventItem: AppStateEventItem ) => eventItem.id !== deletedEvent.id ),
					},
				},
			};

		case 'CREATE_APPRENTICESHIP_SLOT_SUCCESS':
			organizationToChange = find( state.items, [ 'id', action.organizationId ] );
			if ( ! organizationToChange ) {
				return state;
			}

			apprenticeshipToChange = find( organizationToChange.trial_apprenticeships, [ 'id', action.apprenticeshipId ] );
			if ( ! apprenticeshipToChange ) {
				return state;
			}

			return {
				...state,
				items: {
					...state.items,
					[ organizationToChange.slug ]: {
						...organizationToChange,
						trial_apprenticeships: map( organizationToChange.trial_apprenticeships, ( apprenticeship: AppStateTrialApprenticeship ) => {
							if ( apprenticeship.id === action.apprenticeshipId ) {
								return {
									...apprenticeship,
									slots: [ ...apprenticeship.slots, action.slot ],
								};
							}
							return apprenticeship;
						} ),
					},
				},
			};

		case 'UPDATE_APPRENTICESHIP_SLOT_SUCCESS':
			organizationToChange = find( state.items, [ 'id', action.organizationId ] );
			if ( ! organizationToChange ) {
				return state;
			}

			apprenticeshipToChange = find( organizationToChange.trial_apprenticeships, [ 'id', action.apprenticeshipId ] );
			if ( ! apprenticeshipToChange ) {
				return state;
			}

			slotToChange = find( apprenticeshipToChange.slots, [ 'id', action.slotId ] );
			if ( ! slotToChange ) {
				return state;
			}

			return {
				...state,
				items: {
					...state.items,
					[ organizationToChange.slug ]: {
						...organizationToChange,
						trial_apprenticeships: map( organizationToChange.trial_apprenticeships, ( apprenticeship: AppStateTrialApprenticeship ) => {
							if ( apprenticeship.id === action.apprenticeshipId ) {
								return {
									...apprenticeship,
									slots: map( apprenticeship.slots, ( slot: AppStateApprenticeshipSlot ) => {
										if ( slot.id === action.slotId ) {
											return {
												...slot,
												...action.slot,
											};
										}
										return slot;
									} ),
								};
							}
							return apprenticeship;
						} ),
					},
				},
			};

		case 'DELETE_APPRENTICESHIP_SLOT_SUCCESS':
			organizationToChange = find( state.items, [ 'id', action.organizationId ] );
			if ( ! organizationToChange ) {
				return state;
			}

			apprenticeshipToChange = find( organizationToChange.trial_apprenticeships, [ 'id', action.apprenticeshipId ] );
			if ( ! apprenticeshipToChange ) {
				return state;
			}

			slotToChange = find( apprenticeshipToChange.slots, [ 'id', action.slotId ] );
			if ( ! slotToChange ) {
				return state;
			}

			return {
				...state,
				items: {
					...state.items,
					[ organizationToChange.slug ]: {
						...organizationToChange,
						trial_apprenticeships: map( organizationToChange.trial_apprenticeships, ( apprenticeship: AppStateTrialApprenticeship ) => {
							if ( apprenticeship.id === action.apprenticeshipId ) {
								return {
									...apprenticeship,
									slots: apprenticeship.slots.filter( ( slot: AppStateApprenticeshipSlot ) => slot.id !== action.slotId ),
								};
							}
							return apprenticeship;
						} ),
					},
				},
			};

		case 'CREATE_APPRENTICESHIP_REQUEST_SUCCESS':
			organizationToChange = find( state.items, [ 'id', action.organizationId ] );
			if ( ! organizationToChange ) {
				return state;
			}

			apprenticeshipToChange = find( organizationToChange.trial_apprenticeships, [ 'id', action.apprenticeshipId ] );
			if ( ! apprenticeshipToChange ) {
				return state;
			}

			slotToChange = find( apprenticeshipToChange.slots, [ 'id', action.slotId ] );
			if ( ! slotToChange ) {
				return state;
			}

			return {
				...state,
				items: {
					...state.items,
					[ organizationToChange.slug ]: {
						...organizationToChange,
						trial_apprenticeships: map( organizationToChange.trial_apprenticeships, ( apprenticeship: AppStateTrialApprenticeship ) => {
							if ( apprenticeship.id === action.apprenticeshipId ) {
								return {
									...apprenticeship,
									slots: map( apprenticeship.slots, ( slot: AppStateApprenticeshipSlot ) => {
										if ( slot.id === action.slotId ) {
											return {
												...slot,
												...action.slot,
											};
										}
										return slot;
									} ),
								};
							}
							return apprenticeship;
						} ),
					},
				},
			};

		case 'UPDATE_APPRENTICESHIP_REQUEST_SUCCESS':
			organizationToChange = find( state.items, [ 'id', action.organizationId ] );
			if ( ! organizationToChange ) {
				return state;
			}

			apprenticeshipToChange = find( organizationToChange.trial_apprenticeships, [ 'id', action.apprenticeshipId ] );
			if ( ! apprenticeshipToChange ) {
				return state;
			}

			slotToChange = find( apprenticeshipToChange.slots, [ 'id', action.slotId ] );
			if ( ! slotToChange ) {
				return state;
			}

			return {
				...state,
				items: {
					...state.items,
					[ organizationToChange.slug ]: {
						...organizationToChange,
						trial_apprenticeships: map( organizationToChange.trial_apprenticeships, ( apprenticeship: AppStateTrialApprenticeship ) => {
							if ( apprenticeship.id === action.apprenticeshipId ) {
								return {
									...apprenticeship,
									slots: map( apprenticeship.slots, ( slot: AppStateApprenticeshipSlot ) => {
										if ( slot.id === action.slotId ) {
											return {
												...slot,
												...action.slot,
											};
										}
										return slot;
									} ),
								};
							}
							return apprenticeship;
						} ),
					},
				},
			};

		case 'UPDATE_ORGANIZATION_REQUEST':
			return {
				...state,
				isUpdating: {
					...state.isUpdating,
					[ action.data.slug ]: true,
				},
			};

		case 'UPDATE_ORGANIZATION_SUCCESS':
		case 'UPDATE_ORGANIZATION_FAILURE':
			return {
				...state,
				isUpdating: omit( state.isUpdating, [ action.organization.slug ] ),
			};
	}

	if ( ! ( 'query' in action ) ) {
		return state;
	}

	// Collection-based actions

	const {
		stableKey,
		page,
		perPage,
	} = getQueryParts( action.query );

	switch ( action.type ) {
		case 'RECEIVE_ORGANIZATIONS':
			return {
				...state,
				queries: {
					...state.queries,
					[ stableKey ]: getMergedItemIds(
						state.queries[ stableKey ] || [],
						map( action.items, 'slug' ),
						page,
						perPage,
					),
				},
				items: {
					...state.items,
					...keyBy( action.items, 'slug' ),
				},
				pagination: {
					...state.pagination,
					[ stableKey ]: {
						total: action.total,
						totalPages: action.totalPages,
					},
				},
			};

		case 'RECEIVE_ORGANIZATION':
			return {
				...state,
				items: {
					...state.items,
					[ action.organization.slug ]: action.organization,
				},
			};

		case 'FETCH_ORGANIZATIONS':
			return {
				...state,
				isFetching: {
					...state.isFetching,
					[ stableKey ]: true,
				},
			};

		case 'FETCH_ORGANIZATIONS_SUCCESS':
		case 'FETCH_ORGANIZATIONS_FAILURE':
			return {
				...state,
				isFetching: omit( state.isFetching, stableKey ),
			};

		default:
			return state;
	}
}

/**
 * Reducer returning the event operations (CUD) state.
 */
export function eventOperations( state = {
	isCreating: false,
	isUpdating: {},
	isDeleting: {},
}, action ) {
	switch ( action.type ) {
		case 'CREATE_EVENT_REQUEST':
			return {
				...state,
				isCreating: true,
			};

		case 'CREATE_EVENT_SUCCESS':
		case 'CREATE_EVENT_FAILURE':
			return {
				...state,
				isCreating: false,
			};

		case 'UPDATE_EVENT_REQUEST':
			return {
				...state,
				isUpdating: {
					...state.isUpdating,
					[ action.eventId ]: true,
				},
			};

		case 'UPDATE_EVENT_SUCCESS':
		case 'UPDATE_EVENT_FAILURE':
			return {
				...state,
				isUpdating: omit( state.isUpdating, action.eventId ),
			};

		case 'DELETE_EVENT_REQUEST':
			return {
				...state,
				isDeleting: {
					...state.isDeleting,
					[ action.eventId ]: true,
				},
			};

		case 'DELETE_EVENT_SUCCESS':
		case 'DELETE_EVENT_FAILURE':
			return {
				...state,
				isDeleting: omit( state.isDeleting, action.eventId ),
			};

		default:
			return state;
	}
}

/**
 * Reducer returning the apprenticeship slot operations (CUD) state.
 */
export function apprenticeshipSlotOperations( state = {
	isCreating: false,
	isUpdating: {},
	isDeleting: {},
}, action ) {
	switch ( action.type ) {
		case 'CREATE_APPRENTICESHIP_SLOT_REQUEST':
			return {
				...state,
				isCreating: true,
			};

		case 'CREATE_APPRENTICESHIP_SLOT_SUCCESS':
		case 'CREATE_APPRENTICESHIP_SLOT_FAILURE':
			return {
				...state,
				isCreating: false,
			};

		case 'UPDATE_APPRENTICESHIP_SLOT_REQUEST':
			return {
				...state,
				isUpdating: {
					...state.isUpdating,
					[ action.slotId ]: true,
				},
			};

		case 'UPDATE_APPRENTICESHIP_SLOT_SUCCESS':
		case 'UPDATE_APPRENTICESHIP_SLOT_FAILURE':
			return {
				...state,
				isUpdating: omit( state.isUpdating, action.slotId ),
			};

		case 'DELETE_APPRENTICESHIP_SLOT_REQUEST':
			return {
				...state,
				isDeleting: {
					...state.isDeleting,
					[ action.slotId ]: true,
				},
			};

		case 'DELETE_APPRENTICESHIP_SLOT_SUCCESS':
		case 'DELETE_APPRENTICESHIP_SLOT_FAILURE':
			return {
				...state,
				isDeleting: omit( state.isDeleting, action.slotId ),
			};

		default:
			return state;
	}
}

/**
 * Reducer returning the apprenticeship request operations (CUD) state.
 */
export function apprenticeshipRequestOperations( state = {
	isCreating: false,
	isUpdating: {},
}, action ) {
	switch ( action.type ) {
		case 'CREATE_APPRENTICESHIP_REQUEST_REQUEST':
			return {
				...state,
				isCreating: true,
			};

		case 'CREATE_APPRENTICESHIP_REQUEST_SUCCESS':
		case 'CREATE_APPRENTICESHIP_REQUEST_FAILURE':
			return {
				...state,
				isCreating: false,
			};

		case 'UPDATE_APPRENTICESHIP_REQUEST_REQUEST':
			return {
				...state,
				isUpdating: {
					...state.isUpdating,
					[ action.requestId ]: true,
				},
			};

		case 'UPDATE_APPRENTICESHIP_REQUEST_SUCCESS':
		case 'UPDATE_APPRENTICESHIP_REQUEST_FAILURE':
			return {
				...state,
				isUpdating: omit( state.isUpdating, action.requestId ),
			};

		default:
			return state;
	}
}

/**
 * Reducer returning the next menus state.
 */
export function menus( state = {
	isFetching: false,
	items: [],
}, action ) {
	switch ( action.type ) {
		case 'RECEIVE_MENUS':
			return {
				...state,
				items: action.items,
			};

		case 'FETCH_MENUS':
			return {
				...state,
				isFetching: true,
			};

		case 'FETCH_MENUS_SUCCESS':
		case 'FETCH_NEWS_FAILURE':
			return {
				...state,
				isFetching: false,
			};

		default:
			return state;
	}
}

export default combineReducers( {
	authentication,
	token,
	profile,
	notices,
	pages,
	schools,
	teachers,
	districts,
	cities,
	offices,
	professions,
	professionFields,
	professionInterests,
	professionSectors,
	professionDurations,
	professionQualifications,
	apprenticeshipProfessions,
	apprenticeshipLocations,
	apprenticeshipSlotOperations,
	apprenticeshipRequestOperations,
	apprenticeships,
	trialApprenticeships,
	eventOperations,
	events,
	eventAudience,
	eventTopics,
	searchResults,
	form,
	loginModal,
	userConnectionRequest,
	userConnectionDeletion,
	userConnections,
	currentUserRememberedItems,
	users,
	languages,
	nationalities,
	currentUserActivities,
	userActivities,
	userRememberedItems,
	news,
	organizations,
	menus,
	posts,
} );
